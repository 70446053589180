<template>
  <UiContainer tag="ul" class="scrollbar-hidden flex gap-6 overflow-x-scroll pb-3">
    <li>
      <UiDatePickerButton
        :value="datepickerValue"
        class="!py-2.5"
        @click.stop="openCalendar"
        @reset="updateDateFilters(undefined)"
      />

      <UiPopover v-if="isMdAndUp" ref="popover">
        <div class="max-h-[430px] w-[290px] p-3">
          <UiDatePicker
            :model-value="datepickerValue"
            range
            class="scrollbar-hidden max-h-[430px]"
            @update:model-value="updateDateFilters($event)"
          />
        </div>
      </UiPopover>
      <UiDialog v-else v-model="showMobileDatePicker" swipe-close position="bottom">
        <header>
          <UiButton
            icon-transparent
            class="!absolute right-4 top-7 z-20 size-7"
            @click="showMobileDatePicker = false"
          >
            <UiIcon name="x-mark" class="size-5 text-text-main lg:text-light" />
          </UiButton>
        </header>
        <UiDatePicker
          class="scrollbar-hidden z-10 !h-[calc(100dvh_-_16px)] rounded-t-3xl !p-0 !px-5"
          :model-value="datepickerValue"
          range
          need-confirm
          show-reset
          header-classes="mb-3 flex px-0 pt-7"
          @update:model-value="
            ($event) => {
              updateDateFilters($event)
              showMobileDatePicker = false
            }
          "
        >
          <template #header>
            <div>
              <UiTitle severity="h5">календарь</UiTitle>
            </div>
          </template>
          <template #confirm-text>Показать мероприятия</template>
        </UiDatePicker>
      </UiDialog>
    </li>
    <li class="flex gap-3">
      <FilterPriceInSearch
        :model-value="
          filters.filter?.fromPrice || filters.filter?.fromPrice === 0
            ? filters.filter?.fromPrice
            : ''
        "
        :placeholder="`от ${toRub(0)}`"
        :max-value="filters.filter?.tillPrice"
        from
        @update:model-value="updateFromPrice"
      />
      <FilterPriceInSearch
        :model-value="
          filters.filter?.tillPrice || filters.filter?.tillPrice === 0
            ? filters.filter?.tillPrice
            : ''
        "
        :placeholder="`до ${toRub(10000)}`"
        @update:model-value="updateTillPrice"
      />
    </li>
    <li>
      <UiSelect
        :model-value="selectValue"
        :options="selectOptions"
        class="whitespace-nowrap"
        @update:model-value="updateSorting($event.value)"
      />
    </li>
  </UiContainer>
</template>

<script lang="ts" setup>
import { computed, ref, useTemplateRef } from 'vue'
import { usePageFilters } from '@/6_shared/composables'
import { toRub, useBreakpoint } from '@/6_shared/lib'
import {
  type BaseSearchFilters,
  SearchSorting,
  SearchSortingMap,
  type SearchSortingTitle
} from '@/6_shared/model'
import {
  UiButton,
  UiContainer,
  UiDatePicker,
  UiDatePickerButton,
  UiDialog,
  UiIcon,
  UiPopover,
  UiSelect,
  UiTitle
} from '@/6_shared/ui'
import FilterPriceInSearch from './FilterPriceInSearch.vue'

type PropType = {
  pageSize: number
  defaultSelect?: SearchSorting
}
type EmitType = {
  (e: 'change', filters: BaseSearchFilters): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const {
  filters,
  updateDateFilters,
  datepickerValue,
  updateSorting,
  updateFromPrice,
  updateTillPrice
} = usePageFilters<BaseSearchFilters>(props.pageSize, () => emit('change', filters.value))

const selectOptions: { title: SearchSortingTitle; value: SearchSorting }[] = [
  {
    title: SearchSortingMap[SearchSorting.nearest],
    value: SearchSorting.nearest
  },
  {
    title: SearchSortingMap[SearchSorting.cheapest],
    value: SearchSorting.cheapest
  },
  {
    title: SearchSortingMap[SearchSorting.expensive],
    value: SearchSorting.expensive
  },
  {
    title: SearchSortingMap[SearchSorting.popular],
    value: SearchSorting.popular
  }
]
const selectValue = computed(() => {
  const findSelect = (value: SearchSorting) => selectOptions.find((item) => item.value === value)!
  const defaultValue = props.defaultSelect ? findSelect(props.defaultSelect) : selectOptions[0]

  return filters.value.sorting ? findSelect(filters.value.sorting) : defaultValue
})

const {
  md: [isMdAndUp]
} = useBreakpoint()

const openCalendar = (e: MouseEvent) => {
  if (isMdAndUp.value) return showPopover(e)
  showMobileDatePicker.value = true
}

const popover = useTemplateRef<typeof UiPopover>('popover')
const showPopover = (e: MouseEvent) => {
  popover.value && popover.value.show(e)
}
const showMobileDatePicker = ref(false)
</script>
