<template>
  <UiInput
    v-maska="'#####'"
    severity="tertiary"
    v-bind="$attrs"
    :model-value="value || ''"
    :style="{ width: `${size}px` }"
    type="text"
    :container-attrs="{ class: 'max-w-[132px]' }"
    :class="[
      from ? 'min-w-[90px]' : 'min-w-[114px]',
      'flex max-w-[132px] items-center gap-1 ',
      { '!border-transparent text-transparent': value && showValue }
    ]"
    @change="checkValue($event)"
    @focus="showValue = false"
    @blur="showValue = true"
  >
    <template v-if="value && showValue" #prepend>
      <div
        class="absolute z-10 flex size-full cursor-pointer items-center justify-between overflow-hidden whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-text-main"
        @click="showValue = false"
      >
        <UiGradientBackground severity="primary" />
        {{ from ? 'от ' : 'до ' }}{{ toRub(Number(value)) }}
        <UiButton
          icon-transparent
          class="relative z-10"
          @click.stop="emit('update:modelValue', '')"
        >
          <UiIcon name="x-mark" class="size-5" />
        </UiButton>
      </div>
    </template>
  </UiInput>
</template>

<script setup lang="ts">
import { vMaska } from 'maska/vue'
import { computed, ref, watch } from 'vue'
import { toRub } from '@/6_shared/lib'
import { UiButton, UiGradientBackground, UiIcon, UiInput } from '@/6_shared/ui'

type PropType = {
  modelValue: number | ''
  from?: boolean
  maxValue?: number
}
type EmitType = {
  (e: 'update:modelValue', value: number | ''): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const value = computed(() => String(props.modelValue))

const showValue = ref(true)
const size = computed(() => {
  const length = String(props.modelValue).length + 1

  let k = 25
  if (length <= 4) k = 27
  if (length <= 3) k = 34

  return length * k
})

const MAX_VALUE = 10000
const checkValue = (value: string) => {
  showValue.value = true

  if (props.maxValue === 0) return emit('update:modelValue', 0)
  if (props.maxValue && Number(value) >= props.maxValue)
    return emit('update:modelValue', props.maxValue - 1)
  if (Number(value) >= MAX_VALUE) return emit('update:modelValue', MAX_VALUE)

  emit('update:modelValue', value ? Number(value) : '')
}

watch(
  () => props.maxValue,
  (val) => {
    if (
      (props.modelValue || props.modelValue === 0) &&
      (val || val === 0) &&
      Number(val) < Number(props.modelValue)
    )
      return emit('update:modelValue', val === 0 ? 0 : val - 1)
  }
)
</script>
